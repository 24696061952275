import { useState } from 'react';
import BootstrapCarousel from 'react-bootstrap/Carousel';
import './Carousel.css';

const Carousel = ({ images }) => {
    const [index, setIndex] = useState(0);

    return (
        <>
            <nav class='navbar mb-5'>
                <div class='container-fluid'>
                    <a class='navbar-brand' href='/'>&#8592;</a>
                </div>
            </nav>
            <BootstrapCarousel activeIndex={index} onSelect={(idx, e) => setIndex(idx)}>
                {images.map(({ src }) => (
                    <BootstrapCarousel.Item>
                        <img src={src} class="d-block w-100" alt="..." />
                    </BootstrapCarousel.Item>
                ))}
            </BootstrapCarousel>
            <div className="carousel-title">
                <h4 className="mt-4">{String(index).padStart(2, '0')}. {images[index].title}</h4>
            </div>
        </>
    );
}

export default Carousel;