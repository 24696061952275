import section1 from './Images/block-1/evolve-b1-p20.jpg'
import section2 from './Images/block-2/evolve-b2-p20.jpg'
import Card from './Card/Card.js';
import './App.css';

function App() {
  return (
    <div className="App">
      <nav class='navbar mb-5'>
        <div class='container-fluid'>
          <span class='navbar-brand'>andrewJS</span>
        </div>
      </nav>
      <div class='container'>
        <Card src={section1} title='B&W still lifes, pt. 1' link='/bw-1' />
        <Card src={section2} title='B&W still lifes, pt. 2' link='/bw-2' />
      </div>
    </div>
  );
}

export default App;
