import p11 from '../Images/block-2/evolve-b2-p11.jpg';
import p12 from '../Images/block-2/evolve-b2-p12.jpg';
import p13 from '../Images/block-2/evolve-b2-p13.jpg';
import p14 from '../Images/block-2/evolve-b2-p14.jpg';
import p15 from '../Images/block-2/evolve-b2-p15.jpg';
import p16 from '../Images/block-2/evolve-b2-p16.jpg';
import p17 from '../Images/block-2/evolve-b2-p17.jpg';
import p18 from '../Images/block-2/evolve-b2-p18.jpg';
import p19 from '../Images/block-2/evolve-b2-p19.jpg';
import p20 from '../Images/block-2/evolve-b2-p20.jpg';
import Carousel from '../Carousel/Carousel';

const Bw2 = () => {
    return (
        <Carousel images={[
            { src: p11, title: 'garlic, stand, lipstick' },
            { src: p12, title: 'body wash, cup, and onion' },
            { src: p13, title: 'mugs and cube' },
            { src: p14, title: 'apple, pear, and cube' },
            { src: p15, title: 'frame and box' },
            { src: p16, title: 'AC covers, dish, lip balm' },
            { src: p17, title: 'Wi-Fi link, pot, ghost' },
            { src: p18, title: 'paper cranes and cup' },
            { src: p19, title: 'books, owl mug, pot' },
            { src: p20, title: 'books, Dutch house, lemon' },
        ]} />
    );
}

export default Bw2;