import p4 from '../Images/block-1/evolve-b1-p04.jpg';
import p5 from '../Images/block-1/evolve-b1-p05.jpg';
import p6 from '../Images/block-1/evolve-b1-p06.jpg';
import p7 from '../Images/block-1/evolve-b1-p07.jpg';
import p8 from '../Images/block-1/evolve-b1-p08.jpg';
import p9 from '../Images/block-1/evolve-b1-p09.jpg';
import p10 from '../Images/block-1/evolve-b1-p10.jpg';
import p11 from '../Images/block-1/evolve-b1-p11.jpg';
import p12 from '../Images/block-1/evolve-b1-p12.jpg';
import p13 from '../Images/block-1/evolve-b1-p13.jpg';
import p14 from '../Images/block-1/evolve-b1-p14.jpg';
import p15 from '../Images/block-1/evolve-b1-p15.jpg';
import p16 from '../Images/block-1/evolve-b1-p16.jpg';
import p17 from '../Images/block-1/evolve-b1-p17.jpg';
import p18 from '../Images/block-1/evolve-b1-p18.jpg';
import p19 from '../Images/block-1/evolve-b1-p19.jpg';
import p20 from '../Images/block-1/evolve-b1-p20.jpg';
import Carousel from '../Carousel/Carousel';

const Bw1 = () => {
    return (
        <Carousel images={[
            { src: p4, title: 'two cubes' },
            { src: p5, title: 'sphere' },
            { src: p6, title: 'geometric shapes'  },
            { src: p7, title: 'vase and cylinder' },
            { src: p8, title: 'bottle and cubes' },
            { src: p9, title: 'mushroom cone' },
            { src: p10, title: 'vase and tape' },
            { src: p11, title: 'apple, pot, ball' },
            { src: p12, title: 'pear, cylinder, pot' },
            { src: p13, title: 'jug, apple in cylinder' },
            { src: p14, title: 'cone, pear, stand' },
            { src: p15, title: 'bird and birdhouse' },
            { src: p16, title: 'bowling pin, apple, cylinder' },
            { src: p17, title: 'cat on cube' },
            { src: p18, title: 'two rabbits' },
            { src: p19, title: 'two cats'},
            { src: p20, title: 'the pumpkin' },
        ]} />
    );
}

export default Bw1;