import './Card.css';

const Card = ({ src, title, link }) => {
    return (
        <div class='row justify-content-center mb-5'>
            <div class='col card-container'>
                <div class='card text-center' style={{ width: '22rem' }}>
                    <img src={src} alt={title} />
                    <div class='card-body'>
                        <h5 class='card-title'>{title}</h5>
                        <a href={link} class='btn btn-link'>See more</a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;